/* eslint-disable @next/next/no-img-element */
import { useContext } from 'react'
import { LanguageContext } from '../hook/context'
import Rive from '@rive-app/react-canvas'
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import { MaterialSymbolsPlayArrowRounded } from '../data/svgIcons/all-svg-icons'
import { useState } from 'react'

const GetStartButton = dynamic(() => import('./getStartButton'))
const VideoPopUp = dynamic(() => import('./videoPopUp'))
const Banner = () => {
  const { t } = useContext(LanguageContext)

  const [isPlayable, setIsPlayable] = useState({
    button: false,
    video: false,
  })

  const handlePlay = () => {
    const body = document.querySelector('body')
    if (isPlayable.video === false) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'visible'
    }
    setIsPlayable({
      button: !isPlayable.button,
      video: !isPlayable.video,
    })
  }
  return (
    <div className='my-10 flex flex-col items-center justify-center text-center'>
      {t.header.title}
      {t.header.subtitle}
      <GetStartButton />

      {isPlayable.video && (
        <VideoPopUp
          video={isPlayable.video}
          button={isPlayable.button}
          handlePlay={handlePlay}
        />
      )}

      <div
        onClick={() => handlePlay()}
        className='relative group cursor-pointer xl:w-[1360px] xl:h-[730px] w-[390px] h-[270px] sm:w-[728px] sm:h-[390px] md:w-[984px] md:h-[527px] '
      >
        <div className='absolute flex justify-center items-center mx-auto w-full h-full '>
          <button
            className={clsx(
              'flex justify-center items-center bg-black/60 group-hover:scale-125 outline-2 outline outline-white/5 group-hover:outline-white/50 transition-all z-10  backdrop-blur rounded-full p-1 text-white/90 max-w-[60px] max-h-[60px] md:max-w-none md:max-h-max ',
            )}
          >
            <MaterialSymbolsPlayArrowRounded width={75} height={75} />
          </button>
        </div>
        <Rive src='/assets/riveAnimation/minimizeRiv.riv' />
      </div>
    </div>
  )
}

export default Banner
